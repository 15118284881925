<template>
  <div class="logs-page">
    <v-container fluid class="py-6">
      <!-- Block Item Expansion Panel -->
      <v-expansion-panels
        v-model="blockItemPanel"
        :flat="false"
        class="mb-6 expansion-panel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="panel-header">
            <div class="d-flex align-center">
              <v-icon color="error" class="mr-3">mdi-block-helper</v-icon>
              <span class="text-h6 font-weight-medium">Block Item from Fulfillment</span>
            </div>
            <template v-slot:actions>
              <v-icon color="grey darken-1">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="8">
                <v-autocomplete
                  :items="products"
                  item-text="title"
                  return-object
                  v-model="selected_item"
                  prepend-icon="mdi-magnify"
                  label="Search for item to block"
                  clearable
                  rounded
                  outlined
                  dense
                  hide-details="auto"
                  class="mb-4"
                ></v-autocomplete>
                <v-text-field
                  v-if="selected_item"
                  outlined
                  rounded
                  dense
                  label="Reason for blocking this item"
                  :rules="[v => v && v.length > 5 || 'Please provide a reason (min 6 characters)']"
                  v-model="reason"
                  placeholder="e.g., Out of stock, Quality issues, etc."
                  hide-details="auto"
                />
              </v-col>
              <v-col class="d-flex align-center" cols="12" md="4">
                <v-btn
                  color="error"
                  rounded
                  elevation="1"
                  @click="removeItem({ override_default: false })"
                  :loading="blocking_item"
                  :disabled="!selected_item || !reason"
                  class="px-6"
                >
                  <v-icon left>mdi-block-helper</v-icon>
                  Block Item
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- Logs Filter and List -->
      <template v-if="!loading || logs.length">
        <v-expansion-panels
          v-model="filterPanel"
          :flat="false"
          class="mb-6 expansion-panel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="panel-header">
              <div class="d-flex align-center">
                <v-icon color="primary" class="mr-3">mdi-filter-variant</v-icon>
                <span class="text-h6 font-weight-medium">Filter Logs</span>
              </div>
              <template v-slot:actions>
                <v-icon color="grey darken-1">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                multiple
                label="Select log types to display"
                item-text="key"
                item-value="id"
                v-model="selected_filters"
                :items="filters"
                outlined
                rounded
                dense
                chips
                small-chips
                deletable-chips
                background-color="#ffffff"
                hide-details="auto"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="logs-list">
          <transition-group name="log-fade">
            <LogCard 
              v-for="(log, index) in filtered_logs"
              :key="`log-${index}`"
              :log="log"
              class="mb-4"
            />
          </transition-group>
          
          <v-card
            v-if="!filtered_logs.length"
            elevation="2"
            rounded="lg"
            class="empty-state-card text-center pa-6"
          >
            <v-icon size="64" color="grey lighten-1">mdi-text-box-search-outline</v-icon>
            <div class="text-h6 grey--text text--darken-1 mt-4">No logs available</div>
            <div class="text-body-2 grey--text">Try changing your filter settings or check back later</div>
          </v-card>
        </div>
      </template>

      <!-- Loading State -->
      <template v-else>
        <div class="text-center py-12">
          <v-progress-circular
            indeterminate
            :size="64"
            width="5"
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <div class="text-body-1 grey--text text--darken-1">Loading logs...</div>
        </div>
      </template>
    </v-container>
  </div>
</template>
<script>
import LogCard from '@/components/LogCard'
import { mapState } from 'vuex'

export default {
  components: {
    LogCard
  },
  computed: {
    ...mapState({
      logs: state => state.warehouse.logs,
      data_sources: state => state.warehouse.company.data_sources,
      products: state => state.product.products.filter(a => !state.warehouse.company.data_sources.reduce((tot, ds) => !ds.blocked_items ? tot : tot.concat(ds.blocked_items), []).some(b => b.item_uuid === a.uuid)),
    }),
    filtered_logs(){
      const logs = this.logs.concat(this.data_sources.reduce((tot, ds) => 
        tot
          .concat(ds.blocked_items.filter(i => i.message))
          .concat(ds.blocked_orders.filter(i => i.message))
      ,[]))
      logs.sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      return this.selected_filters.length ? logs.filter(log => this.selected_filters.some(i => i === log.type)) : logs
    }
  },
  
  
  filters: {
    
  },
  data() {
    return {
      filters: [
        {
          key: 'Order blocks',
          id: 'blocked_order',
        },
        {
          key: 'Item blocks',
          id: 'blocked_item',
        },
        {
          key: 'Inventory adjustments',
          id: 'inventory_change',
        },
        {
          key: 'Inventory confirmations',
          id: 'inventory_confirmation',
        },
      ],
      selected_filters: [],
      loading: true,
      loading_connection: false,
      code: '',
      blocking_item: false,
      selected_item: null,
      reason: 'Out of stock',
      blockItemPanel: null, // null means closed by default
      filterPanel: null, // null means closed by default
    }
  },
  methods: {
    async removeItem(){
      this.blocking_item = true
      const item = {
        uuid: this.selected_item.uuid,
        name: this.selected_item.title,
      }
      const data_source_id = item.uuid.split(':')[1]
      await this.$store.dispatch('order/block_item', { item, data_source_id, reason: this.reason })
      await this.$store.dispatch('warehouse/retrieve_logs')
      this.blocking_item = false
      this.reason = 'Out of stock'
      this.selected_item = null
    },
    filter_logs(t){
      console.log(t)
    }
  },
  async mounted(){
    await this.$store.dispatch('warehouse/retrieve_logs')
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.logs-page {
  background-color: #f6f6f7;
  min-height: 100%;
}

.expansion-panel {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;
  
  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08) !important;
  }
  
  .panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .v-expansion-panel-content__wrap {
    padding: 16px;
  }
}

.logs-list {
  .log-fade-enter-active, .log-fade-leave-active {
    transition: all 0.5s;
  }
  
  .log-fade-enter, .log-fade-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
  
  .log-fade-move {
    transition: transform 0.5s;
  }
}

.empty-state-card {
  background-color: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease;
  animation: fadeIn 0.8s ease-out;
  
  &:hover {
    transform: translateY(-3px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 960px) {
  .block-item-card,
  .filter-card {
    margin-bottom: 16px;
  }
}
</style>
