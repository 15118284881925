<template>
  <v-card
    elevation="2"
    rounded="lg"
    :class="['log-card', is_unread ? 'unread-log' : '']"
  >
    <v-card-title class="py-3">
      <v-row no-gutters align="center">
        <v-col cols="auto" class="mr-3">
          <v-icon 
            :color="getLogTypeColor(log.type)" 
            size="24"
          >
            {{ getLogTypeIcon(log.type) }}
          </v-icon>
        </v-col>
        <v-col>
          <div class="d-flex align-center">
            <span class="text-subtitle-2 font-weight-medium">{{ getLogTypeLabel(log.type) }}</span>
            <v-chip
              v-if="is_unread"
              x-small
              label
              color="primary"
              class="ml-2"
            >
              New
            </v-chip>
          </div>
          <div class="text-caption text--secondary">
            {{ log.created_at | DateFilter }}
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    
    <v-divider></v-divider>
    
    <v-card-text class="py-4">
      <p class="text-body-1 mb-0">
        {{ log.message }}
      </p>
    </v-card-text>
    
    <v-card-actions v-if="log.type && (log.type === 'blocked_item' || log.type === 'blocked_order')" class="pa-4 pt-0">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        rounded
        elevation="1"
        @click="remove_block"
        :loading="loading_action"
      >
        <v-icon left>mdi-lock-open-variant</v-icon>
        Remove block
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import { DateFilter } from '@/utils/filters'
import { mapState } from 'vuex'

export default {
  filters: {
    DateFilter,
  },
  props: {
    log: {
      type: Object,
      required: true
    }
  },
  components: {
    
  },
  computed: {
    ...mapState({
      read_logs: state => state.user.userProfile.companies.filter(company => company.id === state.warehouse.current_company_id)[0].logs_read_at
    }),
    is_unread(){
      return new Date(this.read_logs).getTime() < new Date(this.log.created_at).getTime()
    }
  },
  data() {
    return {
      loading: false,
      loading_action: false,
    }
  },
  methods: {
    async remove_block(){
      this.loading_action = true
      await this.$store.dispatch('warehouse/perform_log_action', { log: this.log })
      this.loading_action = false
    },
    getLogTypeIcon(type) {
      switch(type) {
        case 'blocked_item':
          return 'mdi-package-variant-remove';
        case 'blocked_order':
          return 'mdi-cart-off';
        case 'inventory_change':
          return 'mdi-clipboard-edit';
        case 'inventory_confirmation':
          return 'mdi-clipboard-check';
        default:
          return 'mdi-information-outline';
      }
    },
    getLogTypeColor(type) {
      switch(type) {
        case 'blocked_item':
        case 'blocked_order':
          return 'error';
        case 'inventory_change':
          return 'warning';
        case 'inventory_confirmation':
          return 'success';
        default:
          return 'info';
      }
    },
    getLogTypeLabel(type) {
      switch(type) {
        case 'blocked_item':
          return 'Item Block';
        case 'blocked_order':
          return 'Order Block';
        case 'inventory_change':
          return 'Inventory Adjustment';
        case 'inventory_confirmation':
          return 'Inventory Confirmation';
        default:
          return 'System Log';
      }
    }
  },
  mounted(){
    
  }
}
</script>
<style lang="scss" scoped>
.log-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  &.unread-log {
    border-left: 4px solid var(--v-primary-base);
    background-color: rgba(var(--v-primary-base-rgb), 0.05) !important;
  }
}
</style>
